import React, { useState } from 'react' // , useEffect, useCallback
import { useFormikContext } from 'formik'
import { StringInput, SelectInput, CheckboxInput } from './inputs'

const StudentRegistrationForm = ({ fileInputChangeHandler }) => {
  const [isSelfSponsor, setIsSelfSponsor] = useState(false)
  const [isSameAddress, setIsSameAddress] = useState(false)
  const { values } = useFormikContext()
  // const [idFile, setIdFile] = useState()

  // const handlePhysicalAddressChange = useCallback((...newValues) => {
  //   values.postalAddress1 = newValues.physicalAddress1
  //   values.postalAddress2 = newValues.physicalAddress2
  //   values.postalSuburb = newValues.suburb
  //   values.postalPostalCode = newValues.postalCode
  // }, [values.postalAddress1, values.postalAddress2, values.postalSuburb, values.postalPostalCode])

  const handleSelfSponsorCheckboxChange = () => {
    setIsSelfSponsor(prevState => !prevState)
  }

  const handleSameAddressCheckboxChange = (event) => {
    if (event.target.checked) {
      values.postalAddress1 = values.physicalAddress1
      values.postalAddress2 = values.physicalAddress2
      values.postalSuburb = values.suburb
      values.postalPostalCode = values.postalCode
    } else {
      values.postalAddress1 = ''
      values.postalAddress2 = ''
      values.postalSuburb = ''
      values.postalPostalCode = ''
    }

    setIsSameAddress(prevState => !prevState)
  }

  // useEffect(() => {
  //   if (isSameAddress) {
  //     handlePhysicalAddressChange()
  //   }
  // }, [values.physicalAddress1, values.physicalAddress2, values.suburb, values.postalCode, handlePhysicalAddressChange, isSameAddress])

  // const handleIdFileChange = (event) => {
  //   console.log(event.target.files)
  //   setIdFile(event.target.files[0])
  // }

  return (
    <section className='form-step'>
      <div className='step-header'>
        <h2>Student Registration <span className='no-break'>(Step 1)</span></h2>
        <p className='under-title'>Welcome and congratulations on taking the first step on an exciting education journey.</p>

        <p>Please follow the steps below and complete the required fields. To process your application, we require you to pay a non-refundable registration fee online via credit/debit card or EFT. Once you have submitted this form and payment has been received, our team will send you an email confirming your application.</p>
      </div>
      <div className='step-inputs form-container'>
        <StringInput label='First Name (as per ID)' name='firstName' type='text' className='c-span-2' />
        <StringInput label='Last Name (as per ID)' name='lastName' type='text' className='c-span-2' />

        <SelectInput label='Title' name='title'>
          <option value="">~ Select Title ~</option>
          <option value="Mr">Mr</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Mrs">Mrs</option>
          <option value="Dr">Dr</option>
          <option value="Prof">Prof</option>
        </SelectInput>

        <StringInput label='Preferred First Name' name='preferredFirstName' tpye='text' />
        <StringInput label='ID Number/Passport Number' name='idNumber' tpye='text' />

        <SelectInput label='Nationality' name='nationality'>
          <option value="">~ Select Nationality ~</option>
          <option value="South African">South African</option>
          <option value="Congo DRC">Congo DRC</option>
          <option value="Liberian">Liberian</option>
          <option value="Zambian">Zambian</option>
          <option value="Zimbabwean">Zimbabwean</option>
          <option value="Unknown">Unknown</option>
          <option value="Other">Other</option>
        </SelectInput>

        <SelectInput label='Gender' name='gender' >
          <option value="">~ Select Gender ~</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="Other">Other</option>
        </SelectInput>

        <SelectInput label='Ethnicity' name='ethnicity' >
          <option value="">~ Select Ethnicity ~</option>
          <option value="Black">Black</option>
          <option value="Coloured">Coloured</option>
          <option value="Indian">Indian</option>
          <option value="White">White</option>
          <option value="Other">Other</option>
        </SelectInput>

        <SelectInput label='Marital Status' name='maritalStatus' >
          <option value="">~ Select Marital Status ~</option>
          <option value="Divorced">Divorced</option>
          <option value="Married">Married</option>
          <option value="Single">Single</option>
          <option value="Widowed">Widowed</option>
          <option value="Unknown">Unknown</option>
        </SelectInput>

        <StringInput label='Home Language' name='homeLanguage' type='text' />

        <fieldset name='contactDetails'>
          <legend>Contact Details</legend>
          <StringInput label='Cellphone' name='cellphoneNumber' type='tel' />
          <StringInput label='Home Number' name='homeNumber' type='tel' />
          <StringInput label='Work Number' name='workNumber' type='tel' />
          <StringInput label='Email' name='email' type='email' />
        </fieldset>

        <fieldset name='sponsorDetails' className={`${isSelfSponsor && 'hidden'}`}>
          <legend>Sponsor Details</legend>
          <CheckboxInput label='I am paying for myself.' name='selfSponsor' checked={isSelfSponsor} onChange={handleSelfSponsorCheckboxChange} />
          <StringInput label='Sponsor First Name' name='sponsorFirstName' type='text' hidden={isSelfSponsor} />
          <StringInput label='Sponsor Last Name' name='sponsorLastName' type='text' hidden={isSelfSponsor} />
          <StringInput label='Sponsor Cellphone' name='sponsorCellphoneNumber' type='tel' hidden={isSelfSponsor} />
          <StringInput label='Sponsor Email' name='sponsorEmail' type='email' hidden={isSelfSponsor} />
        </fieldset>

        <fieldset name='physicalAddress'>
          <legend>Physical Address</legend>
          <StringInput label='Physical Address 1' name='physicalAddress1' type='text' />
          <StringInput label='Physical Address 2' name='physicalAddress2' type='text' />
          <StringInput label='Suburb' name='suburb' type='text' />
          <StringInput label='Postal Code' name='postalCode' type='text' />
        </fieldset>

        <fieldset name='postalAddress'>
          <legend>Postal Address</legend>
          <CheckboxInput label='Same as Physical Address.' name='sameAsPhysicalAddress' checked={isSameAddress} onChange={handleSameAddressCheckboxChange} />
          <StringInput label='Physical Address 1' name='postalAddress1' type='text' disabled={isSameAddress} />
          <StringInput label='Physical Address 2' name='postalAddress2' type='text' disabled={isSameAddress} />
          <StringInput label='Suburb' name='postalSuburb' type='text' disabled={isSameAddress} />
          <StringInput label='Postal Code' name='postalPostalCode' type='text' disabled={isSameAddress} />
        </fieldset>

        <h3>Required Documents</h3>
        <span>
          <label htmlFor="id">Identity Document</label>
          <input 
            type="file" 
            id='id'
            name='Identification_Document' 
            accept='image/png, image/jpeg, .pdf' 
            required 
            onChange={fileInputChangeHandler} 
          />
          {/* {idFile && <img className='uploaded-image' src={URL.createObjectURL(idFile)} alt='Identification Document' />} */}
        </span>
      </div>
    </section>
  )
}

export default StudentRegistrationForm
