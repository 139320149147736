import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'gatsby' // graphql, , useStaticQuery
import { useField } from 'formik'
import { SelectInput, CheckboxInput } from './inputs'

const dateToString = (date) => {
  const optionsObj = {day: 'numeric', month: 'long', year: 'numeric'}
  return new Date(date).toLocaleDateString(undefined, optionsObj)
}

const CourseSelectionForm = ({ learningMethods, courses, chosenCourse, setChosenCourse, fileInputChangeHandler}) => {
  const [upcomingCourses, setUpcomingCourses] = useState([])

  // Get Formik state field values
  const [learningMethodField] = useField('learningMethod')
  const [courseField] = useField('course')

  const filterUpcomingCourses = useCallback((learningMethodInputValue) => {
    const upcomingCoursesArr = courses.filter(course => {
      // const learningMethods = course.courseStartDates.map(startDate => startDate.learningMethod.slug.current)
      const learningMethods = course.courseStartDates.map(startDate => startDate.learningMethod.title)

      if (learningMethods.includes(learningMethodInputValue)) {
        // const startDate = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputValue).startDate
        const startDate = course.courseStartDates.find(instance => instance.learningMethod.title === learningMethodInputValue).startDate

        return new Date(startDate) > new Date()
      } else {
        return false
      }
    })

    setUpcomingCourses(upcomingCoursesArr)
  }, [courses])

  const findCourseInstance = useCallback((learningMethod, courseName) => {
    // const course = courses.find(course => course.slug.current === courseName)
    const course = courses.find(course => course.title === courseName)
    // const courseInstance = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethod)
    const courseInstance = course.courseStartDates.find(instance => instance.learningMethod.title === learningMethod)

    if (!courseInstance) {
      return null
    }
    return {
      title: course.title,
      learningMethod: courseInstance?.learningMethod.title,
      startDate: courseInstance?.startDate,
      paymentOptions: courseInstance?.pricing.paymentOptions
    }
  }, [courses])

  // Sets upcoming courses
  useEffect(() => {
    if (learningMethodField.value !== '') {
      filterUpcomingCourses(learningMethodField.value)
    }
  }, [filterUpcomingCourses, learningMethodField.value])

  // Set chosen course
  useEffect(() => {
    if (learningMethodField.value && courseField.value) {
      const course = findCourseInstance(learningMethodField.value, courseField.value)
      setChosenCourse(course)
    } else {
      setChosenCourse(null)
    }
  }, [learningMethodField.value, courseField.value, findCourseInstance, setChosenCourse])

  // Elements generators
  const learningMethodOptionElements = learningMethods.map(method => (
    <option key={method.slug.current} value={method.title}>{method.title}</option> // value={method.slug.current}
  ))

  const courseOptionElements = upcomingCourses.map(course => (
    <option key={course.slug.current} value={course.title}>{course.title}</option> // value={course.slug.current}
  ))

  const startDateElement = (
    <div className='course-start-date c-span-full'>
      <h3>Course Start Date</h3>
      <p>{chosenCourse ? dateToString(chosenCourse?.startDate) : 'No course chosen'}</p>
    </div>
  )

  return (
    <section className='form-step'>
      <div className="step-header">
        <h2>Course Selection <span className='no-break'>(Step 2)</span></h2>
      </div>
      <div className="step-inputs form-container">
        <SelectInput label='Learning Method' name='learningMethod' className='c-span-2' >
          <option value="">~ Select Learning Method ~</option>
          {learningMethodOptionElements}
        </SelectInput>
        <SelectInput label='Course' name='course' className='c-span-2'>
          <option value="">~ Select Course ~</option>
          {courseOptionElements}
        </SelectInput>

        {chosenCourse && startDateElement}

        <span className='c-span-2'>
          <label htmlFor="matricCertificate">Matric Certificate</label>
          <input 
            type="file" 
            id="matricCertificate" 
            name="Matric_Certificate" 
            accept='image/png, image/jpeg, .pdf' 
            onChange={fileInputChangeHandler} 
          />
        </span>

        <CheckboxInput 
          labelComponent={
            <>I confirm that I have read and understood the <Link to='/terms-and-conditions/'>Terms and Conditions</Link></>
          } 
          name='termsAndConditions' 
        />
      </div>
    </section>
  )
}

export default CourseSelectionForm